import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import EventBus from 'common/EventBus';
import useActiveVoice, { setActiveVoice } from 'hooks/useActiveVoice'
import { Transition } from '@headlessui/react'
import {
	XIcon, ShareIcon
} from '@heroicons/react/outline'
import Waveform from './Waveform'
import {toast} from "react-toastify"
import {getEnv} from "../../common/EnvUtil"
import {useTheme} from "../../hooks/api/themes"

const AudioPlayer: React.FC<{ inStack?: boolean }> = (props) => {
	const { REACT_APP_BASE_IMAGE_URL } = getEnv()
	const history = useHistory()
	const [isShow, setIsShow] = useState(false)
	const [showProfile, setShowProfile] = useState(false)
	const [stackOpen, setStackOpen] = useState(false)
	const activeVoice = useActiveVoice();
	const {data: theme} = useTheme()

	// Add/Remove body class based on isShow state
	useEffect(() => {
		if (isShow) {
			document.body.classList.add('audio-player-active');
		} else {
			document.body.classList.remove('audio-player-active');
		}
		// Clean up when component unmounts or when isShow changes
		return () => {
			document.body.classList.remove('audio-player-active');
		};
	}, [isShow]);

	useEffect( () => {

		EventBus.on( 'stackOpen', () => {
			setStackOpen(true)
		} )

		EventBus.on( 'stackClosed', () => {
			setStackOpen( false )
		} )

		return () => {
			EventBus.remove( 'stackOpen', () => {
				setStackOpen( true )
			} )
			EventBus.remove( 'stackClosed', () => {
				setStackOpen( false )
			} )
		}
	}, [] )// eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if(props.inStack === true){
			setIsShow( activeVoice ? true : false )

		}else if(!props.inStack && !stackOpen){
			setIsShow( activeVoice ? true : false )
		}else{
			setIsShow(false)
		}
	}, [activeVoice, stackOpen])// eslint-disable-next-line react-hooks/exhaustive-deps

	const closePlayerComplete = () => {
		setActiveVoice(null)
	}

	const showVoice = () => {
		history.push(`/explore-voices/${activeVoice?.code}`)
	}

	const getSubTaxons = (sub: string) => {
		if(activeVoice) {
			return activeVoice.filters.filter(filter =>
				filter.hierarchy[1] && filter.hierarchy[1] === sub
			).map(filter =>
				filter.name
			).join("; ")
		}
	}

	const copyLink = () => {
		navigator.clipboard.writeText(
			`${window.location.origin}/explore-voices/${activeVoice?.code}${theme.id ? `?theme=${theme.id}` : ``}`
		).then(() => {
			toast.success("Successfully copied voice link to your clipboard")
		}).catch(() => {
			toast.error("Something went wrong while trying to copy voice link to your clipboard")
		})
	}

	return (

		<Transition.Root show={isShow} as={Fragment}
			enter="transition ease-in-out duration-300 transform"
			enterFrom="translate-y-full"
			enterTo="translate-y-0"
			leave="transition ease-in duration-200 transform"
			leaveFrom="translate-y-0"
			leaveTo="translate-y-full"
			afterLeave={closePlayerComplete}>
			<div className="fixed lg:sticky inset-x-0 bottom-0 z-20 flex flex-shrink-0 h-32 bg-transparent">

				<div className="flex justify-between w-full py-2 px-4 lg:p-4 space-x-4 text-black bg-isabelline-600 shadow-xl-reverse">
					<div className="flex flex-grow space-x-2 lg:space-x-4">
						<div className="relative w-32 md:w-72 md:pl-36 ">
							<div className="absolute inset-0 left-0 w-20 h-20 overflow-hidden bg-gray-300 rounded-md md:w-32 md:h-32 -top-8 md:-top-8 shadow-md-reverse">
								<img src={activeVoice?.image ? REACT_APP_BASE_IMAGE_URL + activeVoice?.image : ''} className="object-cover w-full h-full" alt={activeVoice?.name} />
							</div>

							<span className="hidden mb-2 text-xs uppercase text-blacks-400 md:block ">now playing</span>
							<div className="block mt-14 md:mt-0">
								<span className="inline md:flex items-center text-sm md:mt-0 md:text-base">{activeVoice?.name}</span>
								<button onClick={() => copyLink()} className="absolute top-1 lg:-top-1 right-0 sm:right-0 text-white hover:bg-cobalt-100 rounded-full bg-cobalt-500 ml-2 w-8 h-8 lg:w-6 lg:h-6 flex items-center justify-center">
									<ShareIcon className="w-5 h-5 lg:w-4 lg:h-4"/>
								</button>

								<button className="block -mt-1" onClick={(e) => setShowProfile(!showProfile)}>
									<span
										className="inline text-xs md:mt-0 text-cobalt-500">View profile</span>
								</button>
								<Transition
									show={showProfile}
									as={Fragment}
									enter="transition ease-in-out duration-300 transform"
									enterFrom="translate-y-24 opacity-0 "
									enterTo="opacity-100 translate-y-0 "
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100 translate-y-0"
									leaveTo="opacity-0 translate-y-24"
								>
									<div
										className="absolute p-4 z-50 w-32 md:w-72 mt-1 overflow-y-auto overflow-x-hidden max-h-96 bg-isabelline-600 rounded-md bottom-0 shadow-xl-reverse left-0 sm:text-sm ">
										<div className="flex justify-between">
											<div>
												<button className="absolute top-2 right-2 text-white hover:bg-cobalt-100 rounded-full bg-cobalt-500 ml-2  w-6 h-6 flex items-center justify-center" onClick={(e) => setShowProfile(!showProfile)}>
													<XIcon className="w-4 h-4"/>
												</button>
											</div>
										</div>
										<div className="flex justify-between mt-10">
											<div className="w-24 flex-shrink-0">
												<div className="w-20 h-20 -mt-4 overflow-hidden bg-gray-300 rounded-md">
													<img
														src={activeVoice?.image ? REACT_APP_BASE_IMAGE_URL + activeVoice?.image : ''}
														className="object-cover w-full h-full" alt={activeVoice?.name}/>
												</div>
											</div>
											<div className="flex-grow">
												<span className="text-lg flex items-center">{activeVoice?.name}

												</span>
												<button onClick={() => copyLink()}
														className="absolute top-2 right-10 text-white hover:bg-cobalt-100 rounded-full bg-cobalt-500 ml-2  w-6 h-6 flex items-center justify-center">
													<ShareIcon className="w-4 h-4"/>
												</button>
												<span
													className="text-xs text-gray-900 block">{getSubTaxons('voice_language')}</span>

											</div>
										</div>

										<div className="mt-2 ">
											<span
												className="text-xs text-gray-900 block">{getSubTaxons('voice_age')}</span>
											<span
												className="text-xs mt-2 text-gray-900 block">{getSubTaxons('voice_character')}</span>
											<p className="mt-2 text-xs text-gray-900">{activeVoice?.description}</p>
										</div>
									</div>
								</Transition>
							</div>
							<div className="leading-none truncate">
								{activeVoice?.tags.map((tag) => (
									<span className="mr-1 text-xs text-white" key={tag}>{tag}</span>
								))}
							</div>
						</div>
						<div className="border-r border-blacks-100"></div>
						<div className="flex flex-grow">

							{activeVoice && activeVoice.audio && <Waveform audio={activeVoice.audio }/>}
						</div>
					</div>
					<div>
						<button onClick={() => setIsShow(false)}><XIcon className='w-6 h-6 transition-colors duration-200 hover:text-blacks-600' /></button>
					</div>
		 		</div>
			</div>
		</Transition.Root>

	)
}

export default AudioPlayer
